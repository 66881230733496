import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

// const Box = styled.p`
//   background: blue;
//   height: 50px;
//   width: 50px;
// `

const ErrorMessage = styled.div`
  display: ${props => (props.display ? "block" : "none")};
  color: #aa4040;
  margin-bottom: 10px;
`

const H2 = styled.h2`
  margin-top: 30px;
`

const Input = styled.input`
  height: 32px;
  margin-right: 18px;
  padding-left: 6px;
  padding-right: 6px;
`

const Submit = styled.button`
  background: #1f9ab6;
  color: white;
  border-radius: 5px;
  border: 0;
  height: 32px;
`

class EngagementPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      password: "",
      error: false,
    }
  }

  componentDidMount = () => {
    if (window && window.localStorage.getItem("verified") === "true") {
      window.location.href = "/us"
    }
  }

  updateState = e => {
    this.setState({ password: e.target.value })
  }

  handleClick = () => {
    if (this.state["password"] === "ilovecake") {
      if (window) {
        window.localStorage.setItem("verified", "true")
      }

      window.location.href = "/us"
    } else {
      this.setState({ error: true })
    }
  }

  render() {
    return (
      <Layout>
        <SEO title="Welcome" />
        <H2>What is the password?</H2>
        <ErrorMessage display={this.state["error"]}>
          Sorry - that's not correct!
        </ErrorMessage>
        <Input
          onChange={this.updateState}
          value={this.state["password"]}
          type="text"
        />
        <Submit onClick={this.handleClick}>Submit</Submit>
      </Layout>
    )
  }
}

export default EngagementPage
