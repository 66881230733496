import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { Grid } from "@material-ui/core"
import Upwaltham from "../images/upwaltham.jpg"
import AboutUs from "../images/about_us.jpg"
import Upwaltham2 from "../images/upwaltham_2.jpg"
import Rsvp from "../images/rsvp.jpg"
import Gift from "../images/gift.jpg"
import Bed from "../images/bed.jpg"
import AppleMan from "../images/apple_man.jpg"
import CountrySide from "../images/country_side.jpg"
import FoxGoesFree from "../images/fox_goes_free.jpg"
import Kelowna from "../images/kelowna.jpg"

const GridContainer = styled.div`
  margin: 50px 0 40px;
  padding-top: ${props => (props.hideWhereTo ? "0" : "26px")};
  border-top: ${props => (props.hideWhereTo ? "0" : "1px solid #bbb")};
`

const GridItem = styled(Grid)`
  text-align: center;
  font-size: 32px;
  height: 250px;
`

const GridItemContents = styled.div`
  padding: 50px 0;
  margin: 20px 0;
  background-image: url("${props => props.img}");
  background-size: ${props => props.size};
  background-repeat: no-repeat;
  background-position: ${props =>
    props.position ? "center " + props.position : "center"};
`

const GridItemText = styled.div`
  background: rgba(255, 255, 255);
  opacity: 0.8;
  padding: 40px;
`

const Tile = props => {
  return (
    <GridItemContents {...props}>
      <GridItemText>{props.children}</GridItemText>
    </GridItemContents>
  )
}

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
`

const WhereToHeader = styled.h1`
  margin-bottom: 0;
`

const LinkGrid = props => {
  return (
    <GridContainer hideWhereTo={props.hideWhereTo}>
      {props.hideWhereTo || <WhereToHeader>Where to next?</WhereToHeader>}
      <Grid container direction="row" spacing={2}>
        <GridItem item xs={12} sm={12} md={12}>
          <StyledLink to="/canadian-celebration">
            <Tile img={Kelowna} size="cover">
              Canadian Celebration
            </Tile>
          </StyledLink>
        </GridItem>
        <GridItem item xs={12} sm={6} md={4}>
          <StyledLink to="/about-us">
            <Tile img={AboutUs} size="cover">
              About us
            </Tile>
          </StyledLink>
        </GridItem>
        <GridItem item xs={12} sm={6} md={4}>
          <StyledLink to="/wedding-party">
            <Tile img={AppleMan} position="20%" size="100%">
              Meet the wedding party
            </Tile>
          </StyledLink>
        </GridItem>
        <GridItem item xs={12} sm={6} md={4}>
          <StyledLink to="/location">
            <Tile img={Upwaltham}>Location & Transport</Tile>
          </StyledLink>
        </GridItem>
        <GridItem item xs={12} sm={6} md={4}>
          <StyledLink to="/friday">
            <Tile img={FoxGoesFree} size="cover">
              Friday
            </Tile>
          </StyledLink>
        </GridItem>
        <GridItem item xs={12} sm={6} md={4}>
          <StyledLink to="/saturday">
            <Tile img={Upwaltham2} size="100% 100%">
              Saturday
            </Tile>
          </StyledLink>
        </GridItem>
        <GridItem item xs={12} sm={6} md={4}>
          <StyledLink to="/sunday">
            <Tile img={CountrySide} size="100%">
              Sunday
            </Tile>
          </StyledLink>
        </GridItem>
        <GridItem item xs={12} sm={6} md={4}>
          <StyledLink to="/where-to-stay">
            <Tile img={Bed} size="cover">
              Where to stay
            </Tile>
          </StyledLink>
        </GridItem>
        <GridItem item xs={12} sm={6} md={4}>
          <StyledLink to="/rsvp">
            <Tile img={Rsvp} size="cover">
              RSVP
            </Tile>
          </StyledLink>
        </GridItem>
        <GridItem item xs={12} sm={6} md={4}>
          <StyledLink to="/gifts">
            <Tile img={Gift} size="cover">
              Gifts
            </Tile>
          </StyledLink>
        </GridItem>
      </Grid>
    </GridContainer>
  )
}

export default LinkGrid
