import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"
import LinkGrid from "../components/link-grid"

import "../static/css/layout.css"

const Layout = ({ hideWhereTo, children }) => {
  return (
    <>
      <Header siteTitle="Sara and Jim" />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
        }}
      >
        <main>{children}</main>
        <LinkGrid hideWhereTo={hideWhereTo} />
        <footer></footer>
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
